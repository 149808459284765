header {
  background-color: #000;
  width: 100%;
  height: 75px;
  padding: 1.5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  display: flex;
  align-items: center;
  transform: translate3d(0, 0, 0);
}

header .menu h2 {
  color: #999;
  cursor: pointer;
  transition: 0.345s ease all;
}

header .close-menu {
  display: none;
}

header.header-overlay--visible .close-menu {
  display: block;
}

header.header-overlay--visible .open-menu {
  display: none;
}

header.header-min .menu a,
header.header-min a,
header.header-min .menu-button,
header.header-min .menu h2 {
  color: #fff;
}

header.header-overlay--visible .menu,
header.header-overlay--visible .links a,
header.header-overlay--visible .menu-button {
  color: #fff;
}

header.header-overlay--visible .menu .bar {
  background-color: #000;
}

header.header-overlay--visible a,
header a {
  transition: 0.345s ease color;
}

.logo-link {
  color: #999;
}

.logo-link:hover {
  color: #fff !important;
}

/* header.header-overlay--visible a:hover,
header a:hover {
    color: #999;
} */

header.white-bg {
  background: #000;
  /* transition: .5s ease all .8s; */
}

header.black-bg {
  background: #000;
}

header .menu-button {
  display: flex;
  font-weight: bold;
  align-items: center;
  color: #333;
}

header .menu-button:hover h2 {
  color: #fff;
}

header .menu-button:hover .hamburger:before,
header .menu-button:hover .hamburger:after {
  background: #fff;
}

header .hamburger {
  position: relative;
  height: 15px;
  width: 25px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.345s ease all;
  cursor: pointer;
  z-index: 99;
}

header .hamburger:before,
header .hamburger:after {
  content: "";
  background: #999;
  height: 2px;
  width: 100%;
  transform-origin: 100% 50%;
  transition: 0.345s ease all;
}

header.header-overlay--visible .hamburger:before,
header.header-overlay--visible .hamburger:after {
  transform-origin: 87% 50%;
}

header.header-overlay--visible .hamburger:before {
  transform: rotate(-45deg);
}

header.header-overlay--visible .hamburger:after {
  transform: rotate(45deg);
}

header .menu a {
  padding: 0 1rem;
  font-size: 1rem;
  color: #333;
  text-decoration: none;
}

header .menu .bar {
  height: 2px;
  width: 100%;
  background-color: #000;
}

.header-overlay {
  position: absolute;
  padding: 2rem;
  top: -1.5rem;
  right: -2rem;
  background-color: rgb(17, 17, 17);
  color: #999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.495s, opacity 0.345s ease;
  height: 353px;
  width: 353px;
  box-shadow: 0 0 14px 0 rgba(17, 17, 17, 0.25);
  outline: none;
}

header.header-overlay--visible .header-overlay {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.345s ease, visibility linear 0s;
}

header.header-overlay--visible .page-name-menu {
  opacity: 0;
}

@media screen and (max-width: 600px) {
  .header-overlay {
    width: 100vw;
    height: 100vh;
    left: -16px;
    right: 0;
    padding: 8rem 1rem;
  }
}

@media screen and (max-width: 600px) {
  .page-name-menu {
    display: none;
  }
}

.links {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.links li {
  font-size: 2rem;
  margin: 0 0 2.8rem 0;
  transition: 0.345s ease color, 0.15s ease transform, 0.15s ease opacity;
  opacity: 0;
  transform: translateY(10px);
}

header.header-overlay--visible .links li {
  transform: translateY(0);
  opacity: 1;
}

.links li a:hover {
  color: #ff4350;
}

.email-and-phone {
  display: none;
}

@media screen and (max-width: 600px) {
  .email-and-phone {
    display: block;
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
  }

  .email-and-phone > p > a {
    color: #fff;
  }
}
