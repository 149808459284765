.update-page-single {
    background: #FFF;
    min-height: 100vh;
    padding-top: calc(7rem + 75px);
    opacity: 0;
    transition: .345s ease opacity;
}

.update-page-single.fade-enter-done {
    opacity: 1;
}

.update-description {
    max-width: 100%;
    margin-top: calc(7rem - (75px / 2));
}

.update-content {
    display: flex;
    flex-direction: column;
}

.update-content .side {
    flex: 0 0 50%;
    box-sizing: border-box;
}

.update-content .side .image-side-single {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.update-content .side .image-side-single img {
    display: block;
    width: 100%;
}

.update-content-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.update-content-row .content-flex {
    display: grid;
    grid-template-columns: 1fr 800px 1fr;
    width: 100%;
    grid-gap: 1.5rem;
}

@media screen and (max-width: 1025px) {
    .update-content-row .content-flex {
        grid-template-columns: .3fr .7fr;
    }
}

@media screen and (max-width: 600px) {
    .update-content-row .content-flex {
        grid-template-columns: 1fr;
    }
}

.update-title {
    text-align: "center";
    width: 800px; 
    margin: 0 auto;
}

.update-content-row .content-flex img {
    display: block;
    max-width: 100%;
    height: auto;
}


@media screen and (max-width: 800px) {

    .update-page-single {
        padding-top: 10rem;
    }

    .update-content-row {
        flex-direction: column;
    }

    .update-content-row .content-flex {
        flex: 0 0 100%;
        width: 100%;
    }

    .update-title {
        width: 100%;
    }

}

.update-content-row .content-flex-social {
    flex: 0 0 25%;
    min-width: 300px;
    position: sticky;
    top: 91px;
    align-self: flex-start;
}

@media screen and (max-width: 800px) {
    .update-content-row {
        padding-top: 2.5rem;
    }
}

.related-updates {
    margin-bottom: 1.5rem;
    display: block;
}

.related-title {
    margin-top: .75rem;
}

.update-bottom-info {
    display: flex;
    padding: 0 0 1rem 0;
    position: sticky;
    top: 100px;
}

@media screen and (max-width: 800px) {
    .update-bottom-info {
        width: 100%;
    }
}

.update-bottom-info .profile-image {
    height: 70px;
    width: 70px;
    border: 3px solid #999;
    border-radius: 50%;
    overflow: hidden;
}

.update-bottom-info .author-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.update-share-info {
    padding: 1rem 0;
}
.update-share-info svg {
    margin-right: 1rem;
    transition: color .345s ease;
}

.update-share-info .twitter:hover {
    color: #ff4350 !important;
}

.update-share-info .facebook:hover {
    color: #ff4350 !important;
}

.update-share-info .linkedin:hover {
    color: #ff4350 !important;
}

.update-page-bottom {
    background: #222;
    margin-top: 10rem;
    padding: 9rem 0;
    width: 100%;
    display: flex;
    position: relative;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}

.update-page-bottom .image-scale {
    height: 100%;
    width: 100%;
    transform: scale(1);
    position: absolute;
    top: 0;
    transition: transform .345s ease;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.update-page-bottom:hover .image-scale {
    transform: scale(1.05);
}

.update-page-single .update-page-bottom .container {
    z-index: 99;
    width: 800px;
    margin: 0 auto;
}

@media screen and (max-width: 800px) {
    .update-page-single .update-page-bottom .container {
        width: calc(100% - 2rem);
    }
}

/*** Sidebar Styles ***/