.filter-flex {
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
}

.filter-flex .selected-filter {
    position: absolute;
    left: 0;
    opacity: 1;
    transition: .345s ease all;
    color: #ff4350;
}

.filter-flex:hover .selected-filter {
    transform: translate(0,-10px);
    opacity: 0;
}

.filter-flex .filter {
    margin-right: 1.75rem;
    transform: translate(0, 10px);
    opacity: 0;
    transition: .345s ease all;
    text-transform: capitalize;
    background: #FFF;
}

.filter-flex:hover .filter {
    visibility: visible;
    transform: translate(0,0);
    transition-delay: 0 !important;
    opacity: 1;
}

.filter-flex .filter:hover {
    color: red;
}

.filter-text {
    background: #FFF;
    display: block;
    width: 100%;
    transition: .345s ease transform;
    top: 75px;
    -webkit-overflow-scrolling: touch;
}

.filter-text.scrolled {
    z-index: 99999999;
    width: 100%;
    padding: 0 0 1rem 0;
}
.filter-text.scrolled.up {
    transform: translateY(-100%)
}


@media screen and (max-width: 700px) {
    .filter-flex .filter {
        opacity: 1;
        transform: translate(0,0);
        font-size: 1.15rem;
    }

    .filter-flex .selected-filter {
        display: none;
    }

    .filter-flex .selected .red-underline {
        color: red;
    }
}