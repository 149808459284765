.work-page-single {
    min-height: 100%;
    padding-top: 7rem;
    margin-top: 75px;
    padding-bottom: 0;
    background-color: #000;
}

@media screen and (max-width: 600px) {
    .work-page-single {
        padding-top: 3.5rem;
        padding-bottom: 0;
    }
}

#project-content {
    min-height: 100vh;
    background-color: #000;
    padding-top: 1rem;
}

.single-project-banner {
    background-color: #000;
}

@media screen and (min-width: 601px) and (max-width: 1400px) {
    .single-project-banner {
        height: calc(100% * .5625);
    }
}

@media screen and (min-width: 1400px) {
    .single-project-banner {
        height: calc((100% - 4rem) * .5625);
    }
}

@media screen and (max-width: 600px) {
    .single-project-banner {
        height: calc((100% - 2rem) * .5625);
    }
}

.single-project-banner:not(:first-of-type) {
    margin-top: 2.5rem;
}

.plus {
    transform: rotate(0) scale(1);
    transition: .15s ease-out transform;
}

.open .plus {
    transform: rotate(45deg) scale(1.1);
    transition: .15s ease-in transform;
}

.single-project-banner img,
.single-project-grid .item img {
    width: 100%;
    display: block;
    transition: .5s ease all;
    opacity: 0;
    transform: scale(0.95);
}

.single-project-banner~.single-project-grid {
    margin-top: 1.5rem;
}

.single-project-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
    grid-gap: 1.5rem;
}

.list-row:hover .p-info-button {
    color: #ff4350;
}

@media screen and (max-width: 600px) {
    .single-project-grid {
        grid-template-columns: 1fr;
    }

    .single-project-grid .item:last-of-type {
        /* margin-top: 1.5rem; */
    }

    .p-info-button {
        font-size: 1.3125rem;
    }
}

.single-project-grid .item {
    background-color: #000;
}

.bottom-directions {
    display: flex;
    flex-direction: row;
    flex: 0 0 1;
    justify-content: space-between;
    padding: 6rem 0;
}

.project-container {
    transition: .345s ease opacity;
    padding-bottom: 5rem;
}

.new-project-link {
    transition: .345s ease transform .345s;
    cursor: pointer;
}

/*** Drop Down ***/

.drop-down {
    opacity: 0;
    transform: translateY(10px);
    transition: .25s ease all .2s;
}

.fade-enter-done .drop-down {
    opacity: 1;
    transform: translateY(0);
}


.service-list ul {
    padding-top: 2rem;
}

.service-list li {
    padding: .25rem 0;
    margin-bottom: .1rem;
    display: block;
    line-height: 1.35;
}

.project-description--inner {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    display: none;
    padding: 1rem 0;
}

@media screen and (max-width: 675px) {
    .project-description--inner {
        grid-template-columns: 1fr;
    }

    .service-list {
        margin: 3rem 0;
    }
}

.drop-down--label {
    opacity: 1;
    transition: .15s ease-in-out;
}

.open .drop-down--label {
    opacity: 0;
}

.open~.project-description--inner {
    display: grid;
}



.list-row {
    display: flex;
    align-items: center;
    padding: .5rem 0;
    /* border-top: 1px solid #999; */
    justify-content: flex-start;
    cursor: pointer;
}

.list-row:hover h2 {
    color: #ff4350;
}

.list-row:hover .share-icon svg {
    fill: #ff4350;
    transition: .345s ease all;
}

.list-row .share-icon {
    margin-right: .5rem;
    display: inline-block;
    margin-left: -7px;
}

.list-row .share-icon svg {
    transition: .345s ease fill;
}

.project-content a {
    color: #FFF;
}

.project-content p>span {
    font-weight: 500 !important;
}

.project-content a>span {
    font-weight: 500 !important;
}

/* Next Project Header */

.next-project-container {
    display: flex;
}

.next-project-header {
    transition: 0s ease height, .5s ease top, .5s ease border-top;
    position: relative;
    top: 0;
    cursor: pointer;
}

.next-project-header .project-title,
.next-project-header .project-location,
.next-project-header .next-project-link {
    transition: .25s ease-in-out all;
}

.next-project-header .next-project-link {
    transition-delay: .2s;
    display: flex;
}

.back-to-grid {
    display: flex;
    align-items: flex-start;
    margin-left: auto;
    color: #999;
    fill: #999;
    font-size: 1.45rem;
    padding: 7rem 0;
    font-weight: 500;
    transition: .345s ease color;
}

.back-to-grid:hover {
    color: #ff4350;
    fill: #ff4350;
    cursor: pointer
}

.back-to-grid svg {
    margin-top: 9px;
    margin-left: 1.5rem;
    color: #999;
    transition: .345s ease fill;
}

@media screen and (max-width: 1024px) {
    .back-to-grid {
        width: 100%;
        flex: 0 0 100%;
        padding-top: 7rem;
        padding-bottom: 0;
    }

    .next-project-header {
        width: 100%;
        flex: 0 0 100%;
        padding-bottom: 0rem;
    }

    .next-project-container {
        flex-wrap: wrap;
    }
}

.next-project-header:hover .back-to-grid {
    transform: translateX(-20px);
    transition-delay: 0s;
    transition: .25s ease-in-out all;
    color: inherit;
}

.next-project-header .project-title {
    transition-delay: .15s;
}

.next-project-header .project-location {
    transition-delay: 0s;
}

.next-project-header:hover .next-project-link {
    transform: translateX(20px);
    transition-delay: 0s;
}


.next-project-header:hover .project-title {
    transform: translateX(20px);
    transition-delay: .15s;
}

.next-project-header:hover .project-location {
    transform: translateX(20px);
    transition-delay: .2s;
}

.next-project-header.pre-moving .title-row {
    position: absolute;
    bottom: calc(7rem + 105px);
    transition: 1s ease all;
}

.next-project-header.moving .title-row {
    position: absolute;
    bottom: calc(100% - 7rem - 75px);
    transform: translateY(100%);
    transition: 1s ease all 1s;
}

.single-project-banner img.pop-in,
.single-project-grid .item img.pop-in {
    transform: scale(1);
    opacity: 1;
}

.project-images {
    opacity: 0;
    transition: .345s ease opacity .4s;
}

.fade-enter-done .project-images {
    opacity: 1;
}