#featured-header {
    background-color: transparent;
    /* position: sticky; */
    top: 75px;
    width: 85vw;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
}

@media all and (max-width: 1440px) {
    #featured-header {
        width: calc(100% - 4rem);
    }
}

@media all and (max-width: 600px) {
    #featured-header {
        width: calc(100% - 2rem);
    }
}

.fade-out {
    opacity: 0;
    transition: .25s ease all;
}

.flip-out .next-project-link {
    transform: translateY(10px);
    opacity: 0;
    transition: .25s ease all .15s;
}

.flip-out .project-title {
    transform: translateY(10px);
    opacity: 0;
    transition: .25s ease all .1s;
}

.flip-out .project-location {
    transform: translateY(10px);
    opacity: 0;
    transition: .25s ease all;
}

#featured-header .project-title {
    color: #FFF;
    transition: .345s ease font-size;
    transform: translateY(10px);
    opacity: 0;
    transition: .25s ease all .1s;
}

.fade-enter-done #featured-header .project-title {
    transform: translateY(0);
    opacity: 1;
    transition: .25s ease all;
}

.post-location {
    opacity: 0;
    transform: translateY(10px);
    transition: .25s ease all 0s;
}

.fade-enter-done .post-location {
    opacity: 1;
    transform: translateY(0);
    transition: .25s ease all .1s;
}


.update-page-single #featured-header .project-title {
    transition: .345s ease all;
    color: #000;
}

#featured-header.shrink {
    background-color: #000;
}

.update-page-single #featured-header.shrink {
    background-color: #FFF;
}

#featured-header.shrink .project-title {
    font-size: 1.5rem;
}

#featured-header .title-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    max-width: 1440px;
}

@media screen and (max-width: 960px) {
    #featured-header .title-row {
        grid-template-columns: 1fr;
    }
}

#featured-header .title-row .side {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#featured-header .title-row .side h5 {
    margin-top: -1rem;
    flex: 0 0 100%;
}

@media screen and (max-width: 960px) {
    #featured-header .side.social-side {
        display: none;
    }
}

#featured-header .social-list {
    transform: translateX(100%);
    transition: .345s ease-in-out all;
    background-color: #000;
    opacity: 0;
}

.social-icon {
    position: absolute;
    right: 0;
}

.share-icon:hover {
    color: #ff4350 !important;
    transition: .345s ease-in-out;
    cursor: pointer;
}

#featured-header .social-hover:hover .social-list {
    transform: translateX(0);
    opacity: 1;
}

#featured-header .side:last-of-type .social-list > div {
    margin-left: 1.5rem;
}

#featured-header .side:last-of-type {
    opacity: 0;
    transform: translateY(10%);
    transition: .345s ease all;
}

#featured-header.shrink .side:last-of-type {
    opacity: 1;
    transform: translateY(0);
}



