.contact-page {
    min-height: 100vh;
    background-color: #000;
    padding-top: 70px;
    opacity: 0;
    transition: .345s ease all;
}

.contact-page.fade-enter-done {
    opacity: 1;
}

.contact-header {
    background: #000;
    padding-top: 10.75rem;
}

#form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 0 0 0;
    grid-gap: 1.5rem;
}

@media all and (max-width: 960px) {
    #form-grid {
        display: grid;
        grid-template-columns: 1fr;
    }
}

#form-grid .form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
}

.input-container {
    display: block;
    width: 100%;
}

.input-container label {
    font-size: 1.15rem;
    font-weight: bold;
    margin-bottom: .5rem;
    display: block;
}

.input-container input[type="text"] {
    display: block;
    background:#f1f1f1;
    border: none;
    padding: 1rem 1rem;
    font-size: 1.25rem;
    color: #000;
    outline: none;
    font-weight: 500;
    width: 100%;
    transition: .345s ease all;
}

.input-container input[type="text"]:focus {
    background-color: #edeaea;
}

.input-container textarea {
    border: none;
    background:#f2f3f5;
    padding: 1rem 1rem;
    resize: none;
    width: 100%;
    font-size: 1.25rem;
    outline: none;
    height: 250px;
}

.radio-boxes {
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
}

.radio-boxes .box {
    padding: 1rem 0rem;
    font-size: 1.25rem;
    border: 2px solid #DDD;
    flex: 0 0 33.333%;
    text-align: center;
    font-weight: 500;
    color: #666;
    outline: none;
}

.box:focus {
    background-color: #DDD;
    color: #FFF;
}

.radio-boxes .box:hover,
.radio-boxes .box.selected {
    background: #DDD;
    border: 2px solid #DDD;
    color: #FFF;
}
.radio-boxes .box:first-of-type {
    border-right: none;
}

.radio-boxes .box:last-of-type {
    border-left: none;
}

.floating-contact {
    position: sticky;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.information-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1.5rem;
}

.contact-banner img {
    width: 100%;
    display: block;
}

.blink {
    animation: blink 2.25s steps(2, start) infinite;
}

@keyframes blink  {
    to {
        visibility: hidden;
    }
}

@media all and (max-width: 996px) {
    .information-grid {
        grid-gap: 5rem;
        grid-template-columns: 1fr 1fr;
    }
}


@media all and (max-width: 517px) {
    .information-grid {
        grid-gap: 5rem;
        grid-template-columns: 1fr;
        text-align: left;
    }
}

.link-arrow {
    background: url('./link-arrow.svg');
    width: 11px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-flex;
    align-self: center;
    margin-left: 5px;
    margin-top: 4px;
    transition: .345s ease background-image;
}

.link-arrow.black {
    background: url('./link-arrow-black.svg');
    background-repeat: no-repeat;
}

.link-arrow.red {
/* .arrow-hover:hover .link-arrow { */
    background: url('./link-arrow-red.svg');
    background-repeat: no-repeat;
}