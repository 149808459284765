.cssanimation {
    transition: .5s ease-in-out all;
    opacity: 0;
    transform: translateY(-10%);
}

.cssanimation.translate-opacity.exit {
    opacity: 0;
    transform: translateY(10%);
}

.cssanimation.translate-opacity.enter {
    opacity: 1;
    transform: translateY(0%);
}

.cssanimation.translate-opacity > * {
    line-height: 3;
    transition: .495s ease all;
}


.cssanimation.translate-opacity.enter > * {
    line-height: 1.25;
}