.page-practice {
    padding-top: 70px;
    background: #000;
    min-height: 100vh;
    opacity: 0;
    transition: .345s ease all;
}

.page-practice.fade-enter-done {
    opacity: 1;
}

.top-text {
    padding: 7rem 0;
    max-width: 75%;
}

@media screen and (max-width: 600px) {
    .top-text {
        max-width: 100%;
        padding: 3rem 0;
    }
}

.about-section {
    width: 50%;
}

.lets-work-together {
    width: 75%;
}

@media screen and (max-width: 600px) {
    .lets-work-together {
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .about-section {
        width: 75%;
    }
}

@media screen and (max-width: 600px) {
    .about-section {
        width: 100%;
    }
}

.banner-image {
    padding-top: 7.5rem;
}

.banner-image img {
    display: block;
    width: 100%;
}

.text-cols {
    column-count: 2;
    column-gap: 5rem;
    padding-bottom: 3rem;
}

.white-banner {
    background: #000;
    padding: 14rem 0;
    text-align: left;
    margin-top: 5rem;
}

.changing-text {
    font-size: 3rem;
    line-height: 1.25;
}

@media screen and (max-width: 960px) {
    .text-cols {
        column-count: 1;
        column-gap: 0;
    }
    
}

.image-grid-2 > div {
    width: 100%;
}

.image-grid-2 img {
    display: block;
    width: 100%;
    object-fit: contain;
}

.image-grid-2 .image-container.sticky img {
    position: sticky;
    top: calc(70px + 5rem);
}

.image-grid-2 .image-container:first-of-type {
    padding-right: 1.75rem
}

.image-grid-2 .image-container:last-of-type {
    padding-left: 1.75rem
}


.image-grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem;
}

.display-mobile {
    display: none;
}

@media screen and (max-width: 960px) {
    .image-grid-2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2.5rem;
    }

    .image-grid-2 .image-container:last-of-type {
        display: none;
    }

    .display-mobile {
        display: block;
        padding: 0 !important;
    }
}

.image-container img,
.image-container video {
    width: 100%;
    display: block;
}

.bottom {    
    background: #000;
}

.grid-double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
}

.grid-half {
    width: 100%;
}

.grid-half > div {
    padding-top: 2.025rem;
    /* border-top: 1px solid #999; */
}

.grid-half > div:first-of-type {
    padding-top: 2rem;
}

/*** Animated shit ***/


.animated-scroll-video {
    height: 400vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
}

.scroll-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    top: 70px;
    flex-direction: column;
    justify-content: center;
}

.sticky-scroll-container {
    display: flex;
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 70px;
    justify-content: center;
    align-items: center;
}

.sticky-scroll-container path {
    /* fill: 000; */
    stroke: #FFF;
    stroke-width: 10;
}

.practice-icon {
    width: 30%;
    margin-left: 30%;
    transform: translateX(-50%);
    margin-bottom: 3rem;
    opacity: 1;
    transition: .345s ease opacity;
    position: relative;
}

.info-block:hover .practice-icon {
    opacity: 1;
}

.icon-hover-anim {
    transition: .345s ease all;
    height: 1px;
    width: 1px;
    background: #ff4350;
    position: absolute;
    border-radius: 50%;
}


.icon-hover-anim.design-consult {
    top: 47%;
    right: 32%;
}

.practice-icon:hover .icon-hover-anim.design-consult {
    height: 20%;
    width: 100%;
    right: 0;
}

h1[data-animation-class], h2[data-animation-class], div[data-animation-class], p[data-animation-class] {
    opacity: 0;
    transform: translateY(70px) scale(.99);
    transition: .6s ease all;
    transform-origin: 50% 100%;
}

.animate-in.pop-in {
    transform: translateY(0) scale(1);
    opacity: 1;
}

.info-block:nth-last-of-type(4) {
    transition-delay: 100ms;
}

.info-block:nth-last-of-type(3) {
    transition-delay: 300ms;
}

.info-block:nth-last-of-type(2) {
    transition-delay: 500ms;
}

.info-block:nth-last-of-type(1) {
    transition-delay: 700ms;
}
