#project-list {
    background: #FFF;
    width: 100%;
    padding: 0 0 0 0;
}

.project-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 5rem;
    padding-top: 0rem;
}

.project-grid .project,
.news-grid .project {
    flex-direction: column;
    justify-content: flex-start;
    /* opacity: 0; */
    /* transform: translateY(20%); */
    transition: .345s ease all;
    height: 100%;
}

.project-grid .project.visible,
.news-grid .project.visible {
    transform: translateY(0);
    opacity: 1;
}

.project-grid .project .image-container {
    background-color: #DDD;
}


.gradient {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #FDFDFD 38%, #fafafa 54%);
    background-size: 1000px 640px;
    
    position: relative;
    
}

@keyframes placeHolderShimmer {
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@media all and (min-width: 1440px) {


    .project-grid .project .image-container {
        overflow: hidden;
        height: calc((1440px * .5625 - 44px) / 2);
    }
}
  
@media all and (max-width: 1692px) {
    .project-grid .project .image-container {
        overflow: hidden;
        height: calc(((85vw - 5rem) / 2) * .5625);
    }
}

@media all and (max-width: 1440px) {
    .project-grid .project .image-container {
        will-change: transform;
        overflow: hidden;
        height: calc((((100vw - 4rem) * .5625) / 2) - 1.75rem);
    }
}

.project-grid .project .image-container img,
.news-grid .project .image-container img {
    display: block;
    width: 100%;
    transition: .85s ease all;
}

.project-grid .project:hover .project-title,
.news-grid .project:hover .project-title {
    color: #ff4350;
}

.project-grid .project:hover .image-container img,
.news-grid .project:hover .image-container img {
    transform: scale(1.05);
}

.project .project-description,
.news-grid .project-description {
    padding: 1rem 0 0 0;
}

.project .project-title {
    font-size: 1.85rem;
    font-weight: 700;
    line-height: 1.25;
    opacity: 0;
    transform: translateY(10px);
    transition: .25s ease all .5s;
}

@media screen and (max-width: 600px) {
    .project .project-title {
        font-size: 1.5rem;
    }
}

.project.visible .project-title {
    transform: translateY(0);
    opacity: 1;
}

.project .project-info {
    font-size: 1.5rem;
    padding-top: 1.5rem;
    font-weight: 500;
    line-height: 1.25;
    opacity: 0;
    transform: translateY(10px);
    transition: .25s ease all .57s;
}

.project.visible .project-info {
    transform: translateY(0);
    opacity: 1;
}

.project .details {
    padding: 1.5rem 0 0 0;
    font-size: 1.2rem;
    margin-top: auto;
    opacity: 0;
    transform: translateY(10px);
    transition: .25s ease all .63s;
}


.project.visible .details {
    transform: translateY(0);
    opacity: 1;
}

.homepage-news {
    margin-top: 5rem;
    background: #f2f3f5;
    padding: 5rem 0;
    border-bottom: 1px solid #DEDEDE;
}

.news-grid {
    /* display: grid; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1.75rem;
}

.project .image-container {
    background-color: #DDD;
    /* border-radius: 3px; */
    overflow: hidden;
    opacity: 0;
    transform: scale(0.95)
}
  
.project.visible .image-container {
    opacity: 1;
    transform: scale(1);
    transition: .45s ease all;
}



@media all and (min-width: 1440px) {
    .news-grid .project .image-container {
        overflow: hidden;
        height: calc((1440px / 4 - 1.25rem) * .5625);
    }
}

@media all and (max-width: 1692px) {
    .news-grid .project .image-container {
        overflow: hidden;
        height: calc((85vw / 4 - 1.25rem) * .5625);
    }
}
  
@media all and (max-width: 1440px) {
    .news-grid .project .image-container {
        overflow: hidden;
        height: calc(((100vw - 4rem) / 4 - 1.25rem) * .5625);
    }
}

@media all and (max-width: 600px) {
    .news-grid .project .image-container {
        overflow: hidden;
        height: calc(((100vw - 2rem) / 4 - 1.25rem) * .5625);
    }
}



@media all and (max-width: 700px) {
    .project-grid, .news-grid {
        grid-template-columns: 1fr;
    }

    .project-grid .project .image-container,
    .news-grid .project .image-container {
        will-change: transform;
        overflow: hidden;
        height: calc(((100vw - 2rem) * .5625));
    }
}

.homepage-news .slick-slide {
    height: auto;
    padding-right: 4px
}

.homepage-news .slick-slide:not(:first-of-type) {
    padding-left: 1.75rem;
}