#swipe-slider {
    width: 100%;
    display: block;
    height: auto;
    overflow: hidden;
    cursor: -webkit-grab;
    cursor: grab;
}

#swipe-slider .swipe-track {
    display: inline-flex;
    min-width: 100%;
    width: auto;
    grid-template-columns: min-content;
    justify-content: flex-start;
}

#swipe-slider .swipe-track .swipe-slide {
    margin-left: 1.75rem;
    flex-basis: auto;
    max-width: 25%;
}

#swipe-slider:not(.dragging) .swipe-track {
    transition: -webkit-transform .35s;
    transition: transform .35s;
    transition: transform .35s,-webkit-transform .35s;
    transition-timing-function: cubic-bezier(.25,.46,.45,.94);}

#swipe-slider .swipe-track .swipe-slide:first-of-type {
    margin-left: 0;
}

.arrow-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0 2rem 0;
    width: calc((100% / 4) - 21px);
    margin-right: auto;
}

.slide-arrow-left,
.slide-arrow-right {
    background-image: url('./assets/left-arrow.png');
    background-size: calc(100% - 2rem);
    height: 18px;
    width: 40px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.slide-arrow-right {
    transform: rotate(180deg)
}