#footer {
    display: flex;
    justify-content: center;
    background-color: #f2f3f5;
    color: #f2f3f5;
    padding: 2rem 0;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: -999999;
    transition: all .345s ease;
    opacity: 0;
}


#footer a,
#footer a.light-white {
    line-height: 1.35;
    font-size: 1.2rem;
    color: #000;
    font-weight: 500;
    padding: .25rem 0;
    display: inline-flex;
    align-items: center;
}

#footer a:hover,
#footer a.light-white:hover {
    color: #ff4350;
}

#footer a:first-of-type {
    margin: 0 1rem 0 0;
}

#footer a:nth-of-type(2) {
    margin: 0 1rem 0 0;
}

#footer a:last-of-type {
    margin: 0 0 0 1rem;
}

#footer a.underlined {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

#footer a.underlined:after,
#footer a.underlined:before {
    content: '';
    width: calc(100%);
    height: 2px;
    position: absolute;
    bottom: 2px;
    background-color: #000;
    left: 0;
    transition: .345s ease all;
}

#footer a.underlined:before {
    transform: translateX(-100%);
    transition: .345s ease all .345s;
}


#footer a.underlined:first-of-type:after,
#footer a.underlined:first-of-type:before {
    width: calc(100%);
    left: 0;
}

#footer a.underlined:last-of-type:after,
#footer a.underlined:last-of-type:before {
    width: calc(100%);
    right: 0;
}

#footer a:hover:after {
    transform: translateX(100%);
    background-color: #ff4350;
}

#footer a:hover:before {
    transform: translateX(0%);
    background-color: #ff4350;
}

.sticky-bottom {
    position: absolute;
    bottom: 0;
    padding: .74rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1rem;
    color: #111;
    align-items: center;    
    width: 85%;
    margin: 0 auto;
}

.column-right {
    justify-content: flex-end;
}

.break {
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    align-items: center;
}

.pdc-copy {
    margin-left: 19px !important;
    position: relative;
    display: block;
    font-size: 1.2rem;
    color: #000;
    font-weight: 500;
    position: relative;
    margin-right: 1rem;
}

.pdc-copy:before,
.pdc-copy-mobile:before {
    content: '©';
    font-size: 1.75rem;
    transform: translateX(-1.15rem) !important;
    top: 2px;
    position: absolute;
    background: transparent !important;
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
}

@media screen and (max-width: 1084px) {
    .column-right {
        text-align: left;
    }

    .App {
        margin-bottom: 12rem;
    }

    #footer a {
        display: block;
    }

    #footer a:last-of-type,
    #footer a {
        margin: 0;
    }

    .break {
        flex: 0 0 50%;
        max-width: 100%;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
    }
}

.pdc-copy-mobile {
    display: none;
}

.mobile-visible {
    display: none !important;
}

.mobile-hidden {
    display: block !important;
}

@media screen and (max-width: 1084px) {
    .pdc-copy {
        display: none;
    }

    .pdc-copy-mobile {
        display: block;
        font-size: 1.2rem;
        color: #000;
        font-weight: 500;
        position: relative;
        margin-left: 1rem;
        margin-top: 3rem;
    }

    .mobile-visible {
        display: block !important;
    }
    
    .mobile-hidden {
        display: none !important;
    }

    .App {
        margin-bottom: 14rem;
    }
}