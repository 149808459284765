button {
    border: none;
    padding: .75rem 1.5rem;
    font-size: 1.15rem;
    background-color: #FFF;
    color: #333;
    box-sizing: border-box;
    transition: .345s ease all;
    outline: none;
    cursor: pointer;
    align-self: flex-start;
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    font-weight: bold;
}

* :visited button {
    text-decoration: none;
    color: #FFF;
}

button.black.shadow {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
}

button.shadow:hover {
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
}

button.shadow:active {
    box-shadow: 0 0 18px;
}

.button-sm {
    padding: 0.5rem 1rem;
}

.button-lg {
    padding: 1rem 2rem;
    font-size: 1.25rem;
}

button.filled,
button.grey {
    border: 2px solid #DDD;
    color: #DDD; 
}

button.black {
    background-color: #333;
    color: #FFF;
}

button.black-red {
    background-color: #000;
    color: #FFF;
}

button.black-red:hover {
    background-color: #ff4350;
}

button.center {
    display: inline-flex;
    align-self: center;
}

button.black:hover,
button.black-border:hover {
    background-color: #ff4350;
    border-color: #ff4350;
    color: #FFF;
}


button.white:hover,
button.white-border:hover {
    background-color: #FFF;
    border-color: #FFF;
    color: #000;
}

button.white {
    background-color: #FFF;
}

button.black:active {
    background-color: #444;
}

button.black-border {
    background-color: transparent;
    border: 2px solid #000;
    color: #000;
    padding: calc(.75rem - 2px) calc(1.5rem - 2px);
}

button.grey-border {
    background-color: transparent;
    border: 2px solid #CCC;
    color: #CCC;
    padding: calc(.75rem - 2px) calc(1.5rem - 2px);
}

button.grey-border:hover {
    background: #CCC;
    color: #FFF;
}

button.grey:hover {
    background: #ff4350;
    border: #ff4350 2px solid;
    color: #FFF;
}

button.fit {
    height: 100%;
}

button .button-arrow {
    background-image: url('./arrow-right.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-flex;
    justify-content: flex-start;
    background-position: center center;
    margin-left: 1rem;
    opacity: 0;
    margin-left: calc(-1rem - .25rem);
    transition: .345s ease all;
}

button .button-left-arrow {
    background-image: url('./arrow-left.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-flex;
    justify-content: flex-start;
    background-position: center center;
    opacity: 0;
    margin-right: calc(-1rem - .25rem);
    transition: .345s ease all;
    transform: scaleX(-1)
}

button:hover .button-arrow {
    opacity: 1;
    margin-left: 1rem;
}

button:hover .button-left-arrow {
    margin-right: 1rem;
    opacity: 1;
}

.input-container button {
    padding: 1rem 1.5rem;
    font-size: 1.25rem;
    background-color: #212121;
}