#page-work {
  background-color: #fff;
  padding-top: 75px;
  position: relative;
  opacity: 0;
  transition: 0.345s ease all;
}

#page-work.fade-enter-done {
  background-color: #fff;
  padding-top: 75px;
  position: relative;
  opacity: 1;
}

#work-ticker {
  position: absolute;
  left: 0;
  top: 200px;
}

#work-list {
  background-color: #fff;
  width: 100%;
  padding: 0 0 5rem 0;
}

.filter-box {
  /* background-color: #FFF; */
  padding: 2rem 0;
  display: inline-flex;
}

.filter-box .dropdown {
  border-bottom: 1px dashed #000;
  display: inline-flex;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
  justify-content: center;
}

.image-grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
  transition: 0.345s ease all;
}

.image-grid-row.reverse {
  grid-template-columns: 1fr 1fr;
}

.project {
  cursor: pointer;
}

.project h3 {
  transition: 0.15s ease all;
}

.project:hover h3 {
  color: #000;
}

.project article {
  padding: 0.45rem 0;
}

.image-holder {
  background-color: #999;
  min-height: 100%;
  height: 100%;
}

.image-holder img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.option-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  position: fixed;
  top: 75px;
  width: 100vw;
  background-color: #f2f3f5;
  z-index: 999;
  transition: 0.345s ease all;
  transform: translateY(0%);
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.option-list.up {
  transform: translateY(-100%);
}

.option-list .filter-option {
  display: block;
  margin-right: 2rem;
  font-weight: bold;
  font-size: 1.25rem;
}

.filter-option a {
  color: #000;
  transition: 0.345s ease all;
}

.filter-option a:hover {
  color: #fff;
}

.request-meeting {
  margin-left: auto;
}

.animate {
  transition: 0.5s ease-in-out all;
}

.animate-out {
  transform: translateY(-10%);
  opacity: 0;
}

.filter-text {
  font-size: 1.5rem;
  font-weight: 500;
  overflow-y: auto;
}

@media screen and (max-width: 700px) {
  .showing-text {
    display: none;
  }
}
.clicked {
  animation: fadeIn .495s ease backwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
