.updates-page {
  background: #000;
  min-height: 100vh;
  opacity: 0;
  transition: 0.345s ease opacity;
}

.updates-page.fade-enter-done {
  opacity: 1;
}

.updates-quick-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2.5rem;
  margin-top: 2.5rem;
  padding-bottom: 5rem;
}

.update-item {
  display: flex;
  flex-direction: column;
}

.update-item h5:last-of-type {
  margin-top: auto;
  padding-top: 1.5rem;
}

/* Update Feed */
.update-section {
  /* background: #f2f3f5; */
  width: 100%;
}

.update-feed {
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: column;
  padding-bottom: 6rem;
}

.update-feed .update-row {
  opacity: 0;
  transform: translateY(100px);
  transition: 0.5s ease all;
}

.update-feed .update-row .read-more-update {
  transition: 0.345s ease all;
}

.update-feed .update-row:hover .read-more-update {
  color: #ff4350;
}

.update-feed .update-row.animate-in {
  transform: translateY(0);
  opacity: 1;
}

.update-feed .update-row .update-img img,
.update-feed .update-row .update-img video {
  display: block;
  width: 100%;
}

.update-feed--grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 0;
}

.update-des {
  /* background: #000; */
  padding: 2rem 0 6.5rem 0;
  display: grid;
  grid-template-columns: 1fr;
}

@media all and (max-width: 960px) {
  .update-feed--grid {
    grid-template-columns: 1fr 1fr;
    order: 2;
  }

  .updates-quick-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (max-width: 600px) {
  .update-feed--grid {
    grid-gap: 7rem;
  }

  .updates-quick-grid {
    grid-template-columns: 1fr;
  }

  .update-feed--grid {
    grid-template-columns: 1fr;
    order: 2;
  }
}

.updates-heading {
  padding: 7rem 0;
}

.updates-heading h1 {
  transition: 0.345s ease color;
}

.update-input-container {
  display: flex;
  width: 50%;
  align-items: center;
  transition: 0.345s ease all;
  position: relative;
  z-index: 99;
}

.update-input-container.hide {
  transform: translateX(50px);
  opacity: 0;
  position: absolute;
}

.loader {
  animation: rotate 1s infinite linear;
  position: absolute;
  top: 11.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: #fff;
  background-image: linear-gradient(25deg, #ddd 55%, transparent 60%),
    linear-gradient(50deg, #ddd 55%, transparent 60%),
    linear-gradient(75deg, #ddd 55%, transparent 60%);
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: ease-in 0.345s opacity 0.345s;
}

.loader.show {
  opacity: 1;
  transition: ease-in 0.345s opacity 0.345s;
}

.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 47px;
  height: 47px;
  background: #fff;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.thank-you-text {
  opacity: 0;
  transition: 0.345s ease opacity 1.85s;
  top: 11.5rem;
  /* position: absolute; */
  display: none;
  left: 0;
}

.thank-you-text.show {
  opacity: 1;
  display: block;
}

.update-input {
  border: 0;
  padding: 0.5rem 0;
  font-size: 3rem;
  font-weight: light;
  border-bottom: 1px solid #999;
  outline: none;
  transition: 0.345s ease all;
  color: #ff4350;
  border-radius: 0;
  background: transparent;
}

@media screen and (min-width: 601px) {
  .update-input {
    width: 460px;
  }
}

@media screen and (max-width: 600px) {
  .update-input {
    font-size: 1.5rem;
    width: calc(100% - 66px);
  }
}

.update-input::placeholder {
  color: #999;
  transition: 0.345s ease all;
}

.update-input:focus::placeholder {
  color: #999;
}

.update-input:focus,
.update-input:focus:hover {
  border-color: #ff4350;
}

.signup-button {
  font-size: 1.5rem;
  color: #999;
  /* border-bottom: 1px solid #999; */
  height: 71px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateX(-25%);
  transition: 0.345s ease all;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .signup-button {
    height: 45px;
  }
}

.signup-button svg {
  transition: 0.345s ease all;
}

.signup-button:hover svg {
  transform: translateX(10px) !important;
}

.update-input:focus ~ .signup-button {
  opacity: 1;
  transform: translateX(0);
}

.signup-button > div {
  margin-top: 10px;
}

.hover-underline {
  transition: 0.345s ease-in-out all;
}

@media screen and (max-width: 600px) {
  .hover-underline {
    border: 0;
    padding: 1rem 0 0 0;
  }

  .hover-underline span {
    font-weight: 700;
  }
}

.news-column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: #f2f3f5;
}

.updates-page .news-column a:visited {
  color: #999;
}

.news-side-item {
  padding: 2rem;
  min-height: calc(85vw / 4);
}

@media screen and (min-width: 144px) {
  .news-side-item {
    min-height: calc(1440px / 4);
  }
}

@media (max-width: 1440px) {
  .news-side-item {
    min-height: calc((100vw - 4rem) / 4);
  }
}

.news-side-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 800px) {
  .update-input-container {
    width: 100%;
  }

  .update-share-info {
    width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .news-column {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .update-feed--grid {
    grid-gap: 1rem;
  }

  .news-column {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    background: transparent;
  }
  .news-side-item {
    min-height: calc(100vw - 2rem);
    background: #f2f3f5;
  }

  .update-des {
    grid-template-columns: 1fr;
    padding: 2.5rem 0 2.5rem 0;
  }

  .update-des h3 {
    margin-bottom: 1.5rem;
  }
}
