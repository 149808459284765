#statement {
    padding: 12rem 0 0 0;
    /* background-color: #FAFAFA; */
    display: flex;
}

text {
    fill: #999;
    font-weight: 800;
    font-size: 1.5rem;
    letter-spacing: .6px;
}