#circle-container {
  min-height: 400vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

@media all and (max-width: 600px) {
  #circle-container {
    flex-direction: column;
  }
}

.circles {
  height: 17.5vw;
  width: 17.5vw;
  left: 0;
  top: 300px;
  transform-origin: 50% 50%;
  max-width: 200px;
  max-height: 200px;
  min-width: 100px;
  min-height: 100px;
}

.circles-side {
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: 100vh;
  width: 50%;
}

.sections {
  flex: 0 0 50%;
}

.circle {
  height: 17.5vw;
  width: 17.5vw;
  max-width: 200px;
  max-height: 200px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 17.5vw;
  background: #999;
  position: absolute;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.345s ease background-color;
}

.circle span {
  font-size: 1.65rem;
}

.circle:nth-of-type(1) {
  transform: translate(-75%, 43.3%);
}

.circle:nth-of-type(2) {
  transform: translate(75%, 43.3%);
}

.circle:nth-of-type(3) {
  transform: translateY(-86.6%);
}

.selected-circle {
  background: #ff4350;
  color: #fff;
}

section.circle-sections {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
}

.circle-sections h2 {
  transition: 0.345s ease color 0.345s;
}

@media all and (max-width: 600px) {
  .circles {
    margin-top: 100px;
  }

  .circles-side {
    width: 100%;
    position: sticky;
    top: 43px;
    padding-top: 20px;
    left: 0;
    align-items: flex-start;
    background: #000;
    z-index: 99999;
    height: 40vh;
  }

  .circle span {
    font-size: 1rem;
  }

  .sections {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: cener;
  }

  section.circle-sections {
    padding-left: 0;
    justify-content: center;
    align-items: flex-end;
    width: 80%;
    margin: 0 auto;
  }

  .circle-sections h2 {
    line-height: 1.35;
    font-size: 1.5rem !important;
    padding-bottom: 50%;
  }
  .circle-sections p {
    font-size: 1.5rem !important;
    padding-bottom: 50%;
  }
}
