.careers-page {
    display: block;
    background: #000;
    padding: 75px 0 10rem 0;
    opacity: 0;
    transition: .345s ease all;
}

.careers-page a {
    transition: .345s ease all 0s !important;
}

.careers-page a:hover {
    color: #FF4350;
}

.careers-page.fade-enter-done {
    opacity: 1;
}

.careers-top-text {
    padding: 7rem 0 7rem 0;
    max-width: 75%;
}

@media all and (max-width: 600px) {
    .careers-top-text {
        max-width: 100%;
    }
}

.careers-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0 5rem 0;
    border-bottom: 1px solid #999;
}

@media all and (max-width: 600px) {
    
    .careers-grid {
        padding-top: 1.5rem;
        grid-template-columns: 1fr;
    }
    
    .careers-grid:first-of-type {
        padding-top: 5rem;
    }
    
    .careers-grid h3 {
        margin-bottom: 2rem;
    }
}

.careers-grid:last-of-type {
    border: 0;
}