.logo-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    padding: 20rem 0;
}

.logo-col {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media screen and (max-width: 800px) {
    .logo-grid {
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: auto;
        padding: 2rem 0;
    }

    .logo-col {
        height: 200px;
    }
}


@media screen and (max-width: 600px) {
    .logo-grid {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
        padding: 2rem 0;
    }

    .logo-col {
        height: 200px;
    }
}


.logo-grid img {
    display: block;
    width: 55% !important;
    /* position: relative; */
    opacity: 0;
    position: absolute;
    transition: .345s ease opacity;
}

.logo-grid img:first-of-type {
    opacity: 1;
}