.contact-slider {
    position: relative;
    overflow: hidden;
    /* min-height: 100vh;     */
    display: flex;
    align-items: center;
    padding: 6rem 0 9rem 0;
}

.contact-slider::before,
.contact-slider::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    z-index: 9999;
}

.contact-slider::before {
    left: 0;
    cursor: url('./assets/left-arrow-white.png'), auto;
}

.contact-slider::after {
    right: 0;
    cursor: url('./assets/right-arrow-white.png'), auto;
}

.contact-slider-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    will-change: transform;
}

.contact-slider-track.left {
    animation: SlideLeft 20s linear infinite;
}

.contact-slider-track.right {
    animation: SlideRight 20s linear infinite;
}

.contact-slider-track:hover {
    animation-play-state: paused;
}


.contact-slider .contact-slider-image-container {
    min-width: 400px;
    max-height: 500px;
    margin-right: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.contact-slider .contact-slider-image-container img {
    display: block;
    width: 100%;
    object-fit: contain;
}

.contact-slider .hover-zone {
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
}

.contact-slider .hover-zone.left {
    left: 0;
}

.contact-slider .hover-zone.right {
    right: 0;
}

.contact-slider-track .contact-slider-track {
    animation: none;
}

@keyframes SlideLeft {
    0% {
    transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes SlideRight {
    0% {
    transform: translateX(0);
    }

    100% {
        transform: translateX(50%);
    }
}

@media screen and (max-width: 600px) {
    .contact-slider-track {
        transform: translateX(0) !important;
    }
    .contact-slider { 
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
    }
    .clones {
        display: none;
    }
}