/* Copy of reset.css 2.0 */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

img {
  pointer-events: none;
}

a:visited,
a:active,
a:focus {
  text-decoration: none;
}

a:visited button {
  text-decoration: none;
}

*,
*:before,
*:after {
  text-decoration: none !important;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  background-color: #000;
}

ol,
ul {
  list-style: none;
}

li {
  font-size: 1.45rem;
  line-height: 1.35rem;
  font-weight: 500;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input,
textarea {
  font-family: "Rotis Sans Serif", "Arial", sans-serif;
}

a:visited {
  color: inherit;
}

strong {
  font-weight: 700;
}

/* End reset.css 2.0 */
html {
  min-height: 100%;
  height: auto;
}

html,
body {
  min-height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

body {
  /* background-color: #000; */
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  font-family: "Rotis Sans Serif", "San Francisco", sans-serif;
  line-height: 1.6em;
  color: #000;
}

.careers-links,
p {
  line-height: 1.35;
  font-size: 1.45rem;
  font-weight: 500;
  white-space: pre-wrap;
  display: block;
}

@media screen and (max-width: 600px) {
  p {
    font-size: 1.3125rem !important;
  }

  li {
    font-size: 1.3rem;
  }
}

p > span {
  /* font-weight: 500 !important; */
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
  word-wrap: break-word;
  white-space: pre-wrap;
  position: relative;
  z-index: 1;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light {
  font-weight: 500;
}

h1.lighter,
h2.lighter,
h3.lighter,
h4.lighter,
h5.lighter {
  font-weight: 400;
}

blockquote {
  padding: 10%;
}

blockquote > p {
  font-size: 2.7rem;
  font-weight: 400;
  line-height: 1.2;
  /* font-style: italic; */
}

@media screen and (max-width: 600px) {
  blockquote {
    padding: 8% 0 8% 8%;
  }

  blockquote > p {
    font-size: 1.8rem;
    text-emphasis: italic;
  }
}

h1.light-white,
h2.light-white,
h3.light-white,
h4.light-white,
h5.light-white {
  font-weight: 500;
  color: rgb(242, 242, 242) !important;
}

h1 {
  font-size: 5rem;
  line-height: 1.25;
}

h1.reg {
  font-size: 3rem;
}

@media screen and (max-width: 600px) {
  h1.reg {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.15;
  }
}

h2.reg {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.35;
}

h3.reg {
  font-size: 1.85rem;
  line-height: 1.27;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.15rem;
}

.margin-btm-1 {
  margin-bottom: 1rem;
}

.margin-btm-2 {
  margin-bottom: 2rem;
}

.margin-btm-3 {
  margin-bottom: 3rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.text-sm {
  font-size: 0.78rem;
}

.text-reg {
  font-size: 1rem;
}

.text-md {
  font-size: 1.25rem;
}

.text-bold {
  font-weight: 700;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-block {
  display: block;
}

.text-grey {
  color: #999;
}

.text-blue {
  color: #4a69bd;
}

.text-black {
  color: #000;
}

.text-red {
  color: #ff4350;
}

.text-white {
  color: #fff;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.img-block {
  display: inline-block;
}

.img-block img {
  display: block;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container {
  width: 85%;
  margin: 0 auto;
  max-width: 1440px;
}

.container.padded {
  padding: 3rem 0 2rem 0;
}

@media all and (max-width: 1440px) {
  .container {
    width: calc(100% - 4rem);
  }
}

@media all and (max-width: 700px) {
  .container {
    width: calc(100% - 2rem);
  }
}

.hidden {
  display: none !important;
}

@keyframes animateMenuIn {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@media all and (min-width: 1025px) {
  header .menu-mobile {
    display: none;
  }
}

@media all and (max-width: 600px) {
  h2.reg {
    font-size: 2rem;
  }
  .page-practice h2.reg {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.15;
  }

  h3.reg {
    font-size: 1.5rem;
    line-height: 1.27;
  }

  h3 {
    font-size: 1.25em;
  }

  h3::first-line {
    line-height: 1;
  }

  h4 {
    font-size: 1.25rem;
  }
}

/*** Background classes ***/

.bg-grey {
  background-color: #eee;
}

.bg-dark-grey {
  background-color: #d9d9d9 !important;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

/*** Padding classes ***/

.padding-btm-xs {
  padding-bottom: 0.75rem;
}

.padding-btm-sm {
  padding-bottom: 1rem;
}

.padding-btm-md {
  padding-bottom: 1.25rem;
}

.padding-btm-lg {
  padding-bottom: 2rem;
}

.padding-btm-xl {
  padding-bottom: 3rem;
}

.padding-btm-5 {
  padding-bottom: 5;
}

.padding-top-xs {
  padding-bottom: 0.75rem;
}

.padding-top-half {
  padding-top: 0.5rem;
}

.padding-top-1 {
  padding-top: 1rem;
}

.padding-top-125 {
  padding-top: 1.25rem;
}

.padding-top-15 {
  padding-top: 1.5rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-top-175 {
  padding-top: 1.75rem;
}

.padding-top-3 {
  padding-top: 3rem;
}

.padding-top-4 {
  padding-top: 3rem;
}

.padding-top-5 {
  padding-top: 5rem;
}

.padding-top-7 {
  padding-top: 7rem;
}

.padding-btm-7 {
  padding-bottom: 7rem;
}

.padding-btm-5 {
  padding-bottom: 5rem;
}

.padding-top-6 {
  padding-top: 6rem;
}

.padding-btm-6 {
  padding-bottom: 6rem;
}

.padding-top-10 {
  padding-top: 10rem;
}

.padding-btm-10 {
  padding-bottom: 10rem;
}

/** Components **/

.transition-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  top: 0;
  left: 0;
}

/*** Footer ***/

.App {
  margin-bottom: 6rem;
  min-height: 100vh;
  position: relative;
}

/****** Extra Styles ******/

u {
  text-decoration: none;
  position: relative;
  transition: 0.15s ease-in color;
  cursor: pointer;
  white-space: nowrap;
}

u:hover {
  color: #ff4350 !important;
}

u:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #aaa;
  right: 0;
  bottom: 0;
  transition: 0.25s ease-in-out all 0.125s;
  transform: translateY(3px);
  z-index: -1;
}

u:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  background-color: #ff4350;
  left: 0;
  bottom: 0;
  transition: 0.25s ease-in-out all;
  transform: translateY(3px);
}

u:hover:after {
  width: 0px;
  transition: 0.25s ease-in-out all;
}

u:hover:before {
  width: 100%;
  transition: 0.25s ease-in-out all 0.125s;
}

.transition-curtain {
  height: 100vh;
  width: 100vw;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  transform: translateY(100%);
  z-index: 999;
  transition: 10s ease-in-out transform 5s, visibility 0s linear 10s;
}

.transition-curtain.visible {
  visibility: visible;
  transform: translateY(-100%);
  transition: 0.345s ease-in-out transform;
  animation-name: animateEnterOut;
  animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes animateEnterOut {
  0% {
    transform: translateY(100%);
  }

  30% {
    transform: translateY(0%);
  }

  70% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.top-text-mobile {
  padding-top: calc(7rem);
  padding-bottom: 7rem;
}

@media screen and (max-width: 600px) {
  .top-text-mobile {
    padding: 3.5rem 0;
  }
}

/* Page Transitions */

.fade-enter {
  opacity: 0;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.fade-exit {
  opacity: 1;
  z-index: 1;
  transition: all 345ms ease-out;
  position: absolute;
  width: 100%;
}

.fade-enter-active {
  opacity: 1;
  transition: all 345ms ease-in 1000ms;
}

.fade-exit-active {
  opacity: 0;
  transition: all 345ms ease-out;
}

/* Up Arrow */

.up-arrow {
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  /* background-color: #FFF; */
  border-radius: 50%;
  height: 80px;
  width: 80px;
  /* box-shadow: 0 0 14px 0 rgba(0, 0, 0, .1); */
  background-image: url("https://s3.amazonaws.com/piscatello/UpArrow_Grey.svg");
  background-repeat: no-repeat;
  background-size: 45% 45%;
  background-position: center center;
}

.hover-white:hover {
  color: #fff;
}

.large-br {
  display: none;
}

@media screen and (min-width: 1280px) {
  .large-br {
    display: block;
  }
}
