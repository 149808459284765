.link {
    display: inline-block;
    align-self: flex-start;
    position: relative;
}

:visited .underline {
    color: inherit;
}

.link.grey-grey {
    color: #999;
}

.link.white-grey {
    color: #FFF;
}

:visited .grey-grey .underline {
    color: #999;
}

.selected .underline {
    color: #ff4350;
}

.link.grey-black {
    color: #000;
}



.link.grey-grey:after {
    content: '';
    height: 2px;
    width: 100%;
    background: #999;
    position: absolute;
    left: 0;
    bottom: 2px;
    z-index: 1;
}

.underline {
    /* I don't know why this is needed, I just added it and it worked in IE Edge. If we remove this width, the gradient breaks in IE Edge */
    width: calc(100%);
    background-image: linear-gradient(transparent calc(100% - 2px), #ff4350 2px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    background-position: 100% 100%;
    transition: background-size .345s ease, color ease .345s;
    z-index: 999;
    position: relative;
}
  
  .link:hover .underline,
  .link.hovered .underline,
  .selected .underline {
    background-size: 100% 100%;
    background-position: 0% 100%;
    color: #ff4350;
  }

  a:hover .link-arrow,
  a:hover ~ .link-arrow {
    background: url('../ContactPage/link-arrow-red.svg') no-repeat !important;
  }