.slick-slide img {
  width: 100%;
  display: block;
}

.slick-slide video {
  width: 100%;
  display: block;
}


@media all and (min-width: 1440px) {
  .slick-slide {
    will-change: transform;
    height: calc(1440px * 56.25 / 100);
  }
}

@media all and (min-width: 1440px) {
  .slick-slide {
    will-change: transform;
    height: calc(1440px * 56.25 / 100);
  }
}

@media all and (max-width: 1692px) {
  .slick-slide {
      overflow: hidden;
      height: calc(85vw * 56.25 / 100);
    }
}

@media all and (max-width: 1440px) {
  .slick-slide {
    will-change: transform;
    height: calc((100vw - 4rem) * 56.25 / 100);
  }
}

@media all and (max-width: 600px) {
  .slick-slide {
    will-change: transform;
    height: calc((100vw - 2rem) * 56.25 / 100);
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
-webkit-transform: translate3d(0, 0, 0);
-moz-transform: translate3d(0, 0, 0);
-ms-transform: translate3d(0, 0, 0);
-o-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
transition-delay: 10ms;
}

.slick-cloned {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Custom Styles */
.container.slider {
  margin-bottom: 10rem;
}

@media screen and (max-width: 600px) {
  /* Custom Styles */
  .container.slider {
    margin-top: 0px !important;
    margin-bottom: 7rem;
  }

}
.carousel { height: 53.55vw; }

.carousel .items { width: 100%; height: 100%; }

.flickity-page-dots {
  display: none;
}

.carousel .items img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.description {
  position: sticky;
  color: #FFF;
  padding: 2rem 0;
  bottom: 0;
  margin-bottom: -5rem;
  background-color: rgba(0,0,0,1);
  /* transform: translateY(100%); */
  opacity: 0;
  transition: .345s ease all;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.description.scrolled {
  transform: translateY(0);
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .description {
    position: relative;
    grid-template-columns: 1fr;
  }

  .description .text-right {
    text-align: left;
    padding-top: .25rem;
  }
}

.arrows {
  position: absolute;
  bottom: 0;
  width: 20%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center center;
  z-index: 999;
}

.arrows.left {
  left: 0;
  cursor: url('./assets/left-arrow.png'), auto;
}

.arrows.right {
  right: 0;
  cursor: url('./assets/right-arrow.png'), auto;
}